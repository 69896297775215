<template>
	<div>
		<page-title></page-title>
		<!-- Table Container Card -->
		<b-card class="mt-1">
			<div class="mb-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col class="d-flex align-items-center justify-content-start mb-1 mb-md-0" cols="3">
						<!--						<label>{{ $t("Show") }}</label>-->
						<!--						<v-select v-model="perPage" :clearable="false" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" class="per-page-selector d-inline-block mx-50" size="sm" />-->
						<!--						<label>{{ $t("Entries") }}</label>-->
					</b-col>

					<!-- Search -->
					<b-col cols="6">
						<b-form-input v-model="searchQuery" :placeholder="$t('Search-bar')" class="d-inline-block" @input="searchUser" />
					</b-col>

					<b-col cols="3">
						<b-button
							:to="{
								name: 'admin-users-new',
								params: {
									title: 'User New',
									levels: [
										{ title: 'System Manage' },
										{
											title: 'User List',
											name: 'admin-users-list',
										},
									],
								},
							}"
							class="float-right"
							size="sm"
							variant="primary"
						>
							<feather-icon icon="UserPlusIcon" />
							<span class="text-nowrap ml-1">{{ $t("addUser") }}</span>
						</b-button>
					</b-col>
				</b-row>
			</div>

			<b-table ref="refUserListTable" :empty-text="$t('No Data')" :fields="$t('tableColumns')" :items="user_list" :sort-by.sync="sortBy" :sort-desc.sync="isSortDirDesc" class="position-relative" primary-key="id" responsive show-empty>
				<!-- Column: user_name -->
				<template #cell(user_name)="data">
					<b-media vertical-align="center">
<!--						<template #aside>-->
<!--							<b-avatar :src="String(data.item.user_avatar)" :text="avatarText(data.item.user_name)" size="32" variant="primary" />-->
<!--						</template>-->
						<span class="font-weight-bold d-block text-nowrap">
							{{ data.item.user_name }}
						</span>
						<small class="text-muted">@{{ data.item.user_alias }}</small>
					</b-media>
				</template>
				<!-- Column: phone -->
				<template #cell(phone)="data">
					<div class="text-capitalize">
						{{ data.item.phone }}
					</div>
				</template>
				<!-- Column: project_name -->
				<template #cell(project_name)="data">
					<div v-for="(item1, index) in data.item.projects" :key="data.item.user_id + index" class="text-muted">
						{{ item1.project_name }}
					</div>
				</template>
				<!-- Column: Role -->

				<template #cell(role)="data">
					<div v-for="(item1, index) in data.item.projects" :key="data.item.user_id + index" class="text-nowrap">
						<!-- <feather-icon
              :class="`text-${resolveUserRoleVariant(item1.role.name)}`"
              :icon="resolveUserRoleIcon(item1.role.name)"
              class="mr-50"
              size="18"
            /> -->
						<span class="align-text-top text-capitalize">{{ item1.role.name }}</span>
					</div>
				</template>

				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<div class="align-middle text-center">
						<b-button
							:to="{
								name: 'admin-users-edit',
								params: {
									title: 'User Edit',
									id: data.item.user_id,
									levels: [
										{ title: 'System Manage' },
										{
											title: 'User List',
											name: 'admin-users-list',
										},
									],
								},
							}"
							class="btn-icon mr-1"
							size="sm"
							variant="warning"
						>
							<feather-icon icon="EditIcon" />
						</b-button>
						<b-button class="btn-icon" size="sm" variant="danger" @click="deleteUser(data.item.user_id)">
							<feather-icon icon="Trash2Icon" />
						</b-button>
					</div>
				</template>
			</b-table>

			<b-row>
				<b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
					<span class="text-muted small">{{
						`
                      ${$t("Show")}
                      ${dataMeta.from}
                      ${$t("to")}
                      ${dataMeta.to}
                      ${$t("of")}
                      ${dataMeta.of}
                      ${$t("Entries")}
                      `
					}}</span>
				</b-col>
				<!-- Pagination -->
				<!--				<b-col-->
				<!--					class="-->
				<!--                        d-flex-->
				<!--                        align-items-center-->
				<!--                        justify-content-center justify-content-sm-end-->
				<!--                    "-->
				<!--					cols="12"-->
				<!--					sm="6"-->
				<!--				>-->
				<!--					<b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalUsers" hide-goto-end-buttons size="sm"> </b-pagination>-->
				<!--				</b-col>-->
			</b-row>
		</b-card>
	</div>
</template>

<script>
	import vSelect from "vue-select";
	import store from "@/store";
	import { getCurrentInstance, onMounted, ref } from "@vue/composition-api";
	import { avatarText } from "@/@core/utils/filter";
	import useUsersList from "./useUsersList";
	import { showToast } from "@/libs/utils/showToast";
	import { showConfirm } from "@/libs/utils/showConfirm";
	import PageTitle from "@/views/layout/PageTitle";

	export default {
		components: {
			PageTitle,
			vSelect,
		},

		setup() {
      const user_list = ref([]);
			const {
				// fetchUsers,
				perPage,
				currentPage,
				totalUsers,
				dataMeta,
				perPageOptions,
				sortBy,
				isSortDirDesc,
				refUserListTable,
				refetchData,
				resolveUserRoleIcon,
			} = useUsersList();

			// Register module
			const searchQuery = ref();

			let instance = getCurrentInstance().proxy;

			const searchUser = (value) => {
				// 当用户输入查询条件后，重新渲染列表
				store
					.dispatch("users/fetchUsers", {
						user_name: value,
						pageindex: 1,
						pagesize: 9999,
					})
					.then((response) => {
            console.log('user_list',response.data)
						user_list.value = response.data;
						showToast(null, "成功", "成功获取用户列表");
					})
					.catch((error) => {
						showToast(error);
					});
			};
			const isAddNewUserSidebarActive = ref(false);

			// 删除用户
			const deleteUser = (id) => {
				showConfirm("确认删除?", () => {
					store
						.dispatch("users/deleteUserInfo", id)
						.then((response) => {
							console.log("删除用户");
							if (response) {
								showToast(null, "删除完成", "您已成功删除该用户");
								store
									.dispatch("users/fetchUsers")
									.then((response) => {
										user_list.value = response.data;
									})
									.catch((error) => {
										showToast(error);
									});
							}
						})
						.catch((error) => {
							showToast(error);
						});
				});
			};
			onMounted(() => {
				searchUser();
			});
			return {
				// Sidebar
				isAddNewUserSidebarActive,
				perPage,
				currentPage,
				totalUsers,
				dataMeta,
				searchUser,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refUserListTable,
				refetchData,
				deleteUser,
				// Filter
				avatarText,
        user_list
			};
		},
	};
</script>

<style lang="scss" scoped>
	.per-page-selector {
		width: 90px;
	}
</style>

<style lang="scss">
	//noinspection CssUnknownTarget
	@import "@core/scss/vue/libs/vue-select.scss";

	.table th:nth-last-child(1) {
		text-align: center;
	}
</style>
