var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title'),_c('b-card',{staticClass:"mt-1"},[_c('div',{staticClass:"mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"3"}}),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":_vm.$t('Search-bar')},on:{"input":_vm.searchUser},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-button',{staticClass:"float-right",attrs:{"to":{
								name: 'admin-users-new',
								params: {
									title: 'User New',
									levels: [
										{ title: 'System Manage' },
										{
											title: 'User List',
											name: 'admin-users-list',
										} ],
								},
							},"size":"sm","variant":"primary"}},[_c('feather-icon',{attrs:{"icon":"UserPlusIcon"}}),_c('span',{staticClass:"text-nowrap ml-1"},[_vm._v(_vm._s(_vm.$t("addUser")))])],1)],1)],1)],1),_c('b-table',{ref:"refUserListTable",staticClass:"position-relative",attrs:{"empty-text":_vm.$t('No Data'),"fields":_vm.$t('tableColumns'),"items":_vm.user_list,"sort-by":_vm.sortBy,"sort-desc":_vm.isSortDirDesc,"primary-key":"id","responsive":"","show-empty":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(user_name)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(data.item.user_name)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v("@"+_vm._s(data.item.user_alias))])])]}},{key:"cell(phone)",fn:function(data){return [_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(data.item.phone)+" ")])]}},{key:"cell(project_name)",fn:function(data){return _vm._l((data.item.projects),function(item1,index){return _c('div',{key:data.item.user_id + index,staticClass:"text-muted"},[_vm._v(" "+_vm._s(item1.project_name)+" ")])})}},{key:"cell(role)",fn:function(data){return _vm._l((data.item.projects),function(item1,index){return _c('div',{key:data.item.user_id + index,staticClass:"text-nowrap"},[_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(item1.role.name))])])})}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"align-middle text-center"},[_c('b-button',{staticClass:"btn-icon mr-1",attrs:{"to":{
								name: 'admin-users-edit',
								params: {
									title: 'User Edit',
									id: data.item.user_id,
									levels: [
										{ title: 'System Manage' },
										{
											title: 'User List',
											name: 'admin-users-list',
										} ],
								},
							},"size":"sm","variant":"warning"}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1),_c('b-button',{staticClass:"btn-icon",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.deleteUser(data.item.user_id)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1)]}}])}),_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted small"},[_vm._v(_vm._s((" " + (_vm.$t("Show")) + " " + (_vm.dataMeta.from) + " " + (_vm.$t("to")) + " " + (_vm.dataMeta.to) + " " + (_vm.$t("of")) + " " + (_vm.dataMeta.of) + " " + (_vm.$t("Entries")) + " ")))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }